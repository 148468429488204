.container__gallery {
    /* height: 100vh; */
    margin: auto;
    display: flex;
    /* justify-content: flex-start; */
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: auto;
    /* background: #252525; */
  }
  
  .imgBx {
    position: relative;
    width: 300px;
    height: 300px;
    padding: 5px;
    /* margin: 10px; */
    /* border: 3px solid #cab4f7; */
    overflow: hidden;
  }
  .imgBx img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
  .contentBx {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    display: flex;
    opacity: 0;
    /* transform: translateY(100px); */
    transition: 0.2s;
  }
  .imgBx:hover .contentBx {
    opacity: 1;
    padding: 5px;
    transform: translateY(0);
  }
  .sm-box {
    width: 100px;
  }
  .lg-box {
    width: 200px;
  }
  .box {
    display: inline-block;
    height: 100px;
    background: linear-gradient(0deg, #000000, #00000000);
    /* border: 1px solid #0000007a; */
    font-size: 24px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* cursor: pointer; */
  }
  .contentBx .box:hover {
    /* background: #000000b4; */
    /* font-size: 28px; */
  }
  