.accordion {
  width: 80%;
  height: 100%;
  perspective: 900;

  min-height: 0;
  display: inline-block;
  position: relative;
  left: 50%;
  margin: 0 0 12rem 0;
  transform: translate(-50%, 0);
}
.accordion .transition, ul li i:before, ul li i:after, p {
  transition: all 0.5s ease-in-out;
}

.accordion .flipIn, ul li, h1 {
  animation: flipdown 0.5s ease both;
}

.accordion .no-select, h2 {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.accordion h1, h2 {
  color: var(--vortex-orange);
}

.accordion h1 {
  text-transform: uppercase;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 3px;
  font-weight: 100;
}

.accordion h2 {
  font-size: 26px;
  line-height: 34px;
  font-weight: 700;
  letter-spacing: 1px;
  display: block;
  margin: 0;
  cursor: pointer;
}

.accordion p {
  padding: 2rem;
  color: var(--white);
  font-size: 1.5rem;
  line-height: 26px;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  max-height: 800px;
  opacity: 1;
  transform: translate(0, 0);
  margin-top: 14px;
  z-index: 2;
}

.accordion ul {
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;
}
.accordion ul li {
  position: relative;
  padding: 0;
  margin: 0;
  padding-bottom: 4px;
  padding-top: 18px;
  border-top: 1px dotted #dce7eb;
}
.accordion ul li:nth-of-type(1) {
  animation-delay: 0.5s;
}
.accordion ul li:nth-of-type(2) {
  animation-delay: 0.75s;
}
.accordion ul li:nth-of-type(3) {
  animation-delay: 1s;
}
.accordion ul li:nth-of-type(4) {
  animation-delay: 1.2s;
}
.accordion ul li:last-of-type {
  padding-bottom: 0;
}
.accordion ul li i {
  position: absolute;
  transform: translate(-6px, 0);
  margin-top: 16px;
  right: 0;
}
.accordion ul li i:before, ul li i:after {
  content: "";
  position: absolute;
  background-color: var(--vortex-orange);
  width: 3px;
  height: 9px;
}
.accordion ul li i:before {
  transform: translate(-2px, 0) rotate(45deg);
}
.accordion ul li i:after {
  transform: translate(2px, 0) rotate(-45deg);
}
.accordion ul li input[type=checkbox] {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}
.accordion ul li input[type=checkbox]:checked ~ p {
  margin-top: 0;
  max-height: 0;
  opacity: 0;
  transform: translate(0, 50%);
}
.accordion ul li input[type=checkbox]:checked ~ i:before {
  transform: translate(2px, 0) rotate(45deg);
}
.accordion ul li input[type=checkbox]:checked ~ i:after {
  transform: translate(-2px, 0) rotate(-45deg);
}

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }
  5% {
    opacity: 1;
  }
  80% {
    transform: rotateX(8deg);
  }
  83% {
    transform: rotateX(6deg);
  }
  92% {
    transform: rotateX(-3deg);
  }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}