@import url(sections/whatsapp.css);
@import url(sections/news.css);
@import url(gallery.css);
@import url(sections/accordion.css);
@import url(contact.css);

/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

  /**
   * colors
   */

  --rich-black-fogra-29_95: hsla(222, 18%, 11%, 0.95);
  --raisin-black-1: hsl(0, 0%, 16%);
  --raisin-black-2: hsl(236, 17%, 17%);
  --raisin-black-3: hsl(280, 11%, 11%);
  --raisin-black-4: hsl(280, 8%, 15%);
  --english-violet: hsl(274, 21%, 23%);
  --eerie-black-1: hsl(277, 25%, 10%);
  --eerie-black-2: hsl(280, 7%, 8%);
  --roman-silver: hsl(220, 6%, 59%);
  --quick-silver: hsl(0, 1%, 65%);
  --light-gray-1: hsl(0, 0%, 80%);
  --light-gray-2: hsl(0, 2%, 82%);
  --vortex-orange_75: hsla(25, 97%, 51%, 0.75);
  --xiketic_90: hsla(280, 37%, 8%, 0.9);
  --cultured-2: hsl(0, 0%, 97%);
  --vortex-orange: hsl(25, 97%, 51%);
  --vortex-blue: hsl(231, 93%, 52%);
  --platinum: hsl(0, 0%, 89%);
  --dim-gray: hsl(0, 0%, 42%);
  --white_15: hsla(0, 0%, 100%, 0.15);
  --white_10: hsla(0, 0%, 100%, 0.1);
  --xiketic: hsl(277, 25%, 10%);
  --silver: hsl(0, 0%, 78%);
  --white: hsl(0, 0%, 100%);
  --jet: hsl(236, 13%, 23%);

  /**
   * typography
   */

  --ff-poppins: 'Poppins', sans-serif;

  --fs-1: 7rem;
  --fs-2: 4.5rem;
  --fs-3: 3.6rem;
  --fs-4: 2.4rem;
  --fs-5: 2.2rem;
  --fs-6: 2rem;
  --fs-7: 1.6rem;
  --fs-8: 1.5rem;
  --fs-9: 1.4rem;
  --fs-10: 1.3rem;
  --fs-11: 1.2rem;

  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;

  /**
   * spacing
   */

  --section-padding: 120px;

  /**
   * gradient
   */

  --gradient: radial-gradient(circle, hsl(250, 7%, 17%), hsl(250, 11%, 11%));

  /**
   * box shadow
   */

  --shadow-1: 0px 2px 8px 0px hsla(0, 0%, 0%, 0.2),
              inset 0px 2px 8px 0px hsla(0, 0%, 0%, 0.4);
  --shadow-2: 0px 5px 10px 1px hsla(0, 0%, 0%, 0.4);
  --shadow-3: 0px 5px 10px 1px hsla(219, 98%, 17%, 0.2);
  --shadow-4: 0px 5px 10px 1px hsla(0, 0%, 0%, 0.15);

  /**
   * transition
   */

  --transition: 0.25s ease;
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);

}





/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li { list-style: none; }

a {
  text-decoration: none;
  color: inherit;
}

a,
img,
span,
time,
input,
textarea,
button,
ion-icon { display: block; }

img { height: auto; }

input,
button,
textarea {
  background: none;
  border: none;
  font: inherit;
}

input,
textarea { width: 100%; }

button { cursor: pointer; }

ion-icon { pointer-events: none; }

address { font-style: normal; }

html {
  font-family: var(--ff-poppins);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--raisin-black-3);
  font-size: 1.6rem;
  line-height: 1.5;
}

:focus-visible { outline-offset: 4px; }





/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container { padding-inline: 15px; }

.section { padding-block: var(--section-padding); }

.h1,
.h2,
.h3 {
  color: var(--white);
  font-family: var(--ff-poppins);
  font-weight: var(--fw-800);
  line-height: 1;
}

.h1 {
  font-size: var(--fs-2);
  letter-spacing: -3px;
}

.h2 { font-size: var(--fs-3); }

.h3 {
  font-size: var(--fs-6);
  text-transform: uppercase;
}

:is(.h1, .h2, .h3, .card__inst) .span {
  display: inline-block;
  color: var(--vortex-orange);
}

.btn {
  margin-inline: auto;
  color: var(--eerie-black-1);
  font-size: var(--fs-8);
  text-transform: uppercase;
  font-weight: var(--fw-700);
  min-height: 55px;
  padding-inline: 35px;
}

.skewBg {
  position: relative;
  z-index: 1;
}

.skewBg::before {
  content: "";
  position: absolute;
  inset: 0;
  transform: skewX(var(--skewX, -16deg));
  background-color: var(--bg, var(--vortex-orange));
  z-index: -1;
}


.btn:is(:hover, :focus)::after { --translateX: -13px; }

.has__scrollbar {
  display: flex;
  gap: 30px;
  overflow-x: auto;
  padding-block-end: 30px;
  scroll-snap-type: inline mandatory;
}

.has__scrollbar::-webkit-scrollbar { height: 10px; }

.has__scrollbar::-webkit-scrollbar-track { outline: 3px solid var(--vortex-orange); }

.has__scrollbar::-webkit-scrollbar-thumb { background-color: var(--vortex-orange); }

.has__scrollbar::-webkit-scrollbar-button { width: calc(25% - 40px); }

.section__subtitle,
.section__title {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.section__subtitle {
  color: var(--silver);
  font-size: var(--fs-9);
  font-weight: var(--fw-700);
  margin-block-end: 10px;
}

.scrollbar__item {
  min-width: 100%;
  scroll-snap-align: start;
}

.card__banner { background-color: var(--light-gray-1); }

.img__holder { aspect-ratio: var(--width) / var(--height); }

.img__cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-badge {
  min-height: 30px;
  color: var(--eerie-black-1);
  font-size: var(--fs-11);
  font-weight: var(--fw-700);
  text-transform: uppercase;
  max-width: max-content;
  display: grid;
  align-items: center;
  padding-inline: 20px;
}

.card-badge::before { --skewX: -10deg; }

.w-100 { width: 100%; }

.section__text {
  color: var(--color, var(--light-gray-1));
  font-size: var(--fs-8);
  font-weight: var(--fw-500);
  max-width: 65ch;
  margin-inline: auto;
  text-align: center;
  line-height: 1.8;
}





/*-----------------------------------*\
  #HEADER
\*-----------------------------------*/

/* .header-top,
.cart-btn,
.header__bottom::before,
.navbar__link::before {
  display: none;
} */

.header {
  position: relative;
  min-height: 1px;
}

.header__bottom {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  width: 100%;
  background-color: var(--raisin-black-2);
  padding-block: 20px;
  z-index: 4;
}

.header__bottom.active {
  position: fixed;
  top: -85px;
  animation: slideIn 0.5s var(--cubic-out) forwards;
}

@keyframes slideIn {
  0% { transform: translateY(0); }
  100% { transform: translateY(100%); }
}

.header__bottom .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  color: var(--white);
  font-family: var(--ff-poppins);
  font-size: 3rem;
  font-weight: var(--fw-800);
}

.header__actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-btn {
  color: var(--vortex-orange);
  padding: 13px;
  box-shadow: var(--shadow-1);
  border-radius: 4px;
}

.search-btn ion-icon { --ionicon-stroke-width: 80px; }

.nav__toggle__btn {
  color: var(--white);
  font-size: 30px;
  /* border: 1px solid var(--white); */
  padding: 4px;
}

.nav__toggle__btn.active .menu,
.nav__toggle__btn .close { display: none; }

.nav__toggle__btn .menu,
.nav__toggle__btn.active .close { display: block; }

.navbar {
  background-color: var(--eerie-black-1);
  color: var(--white);
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  max-width: 350px;
  visibility: hidden;
  max-height: 0;
  transition: 0.25s var(--cubic-out);
  overflow: hidden;
}

.navbar.active {
  visibility: visible;
  max-height: 100vh;
  transition-duration: 0.5s;
}

.navbar__item:not(:last-child) { border-block-end: 1px solid var(--white_15); }

.navbar__item:hover {
  height: 0.2rem;
  transform: skewX(var(--skewX, -16deg));
  background-color: var(--bg, var(--vortex-orange));
  color: white !important;
}

.navbar__link {
  padding: 10px 25px;
  transition: var(--transition);
}

.navbar__link:is(:hover, :focus) {
  background-color: var(--white_10);
}





/*-----------------------------------*\
  #SEARCH BOX
\*-----------------------------------*/

.search-container {
  background-color: var(--rich-black-fogra-29_95);
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 40px;
  z-index: 6;
  visibility: hidden;
  opacity: 0;
  transition: var(--transition);
}

.search-container.active {
  visibility: visible;
  opacity: 1;
}

.search-container .input-wrapper {
  position: relative;
  max-width: 560px;
  width: 100%;
}

.search-container .search-field {
  color: var(--white);
  font-size: 2rem;
  padding: 20px;
  padding-inline-end: 60px;
  border-block-end: 3px solid var(--white_10);
  outline: none;
  transition: var(--transition);
}

.search-container .search-field:focus { border-color: var(--light-gray-2); }

.search-container .search-field::-webkit-search-cancel-button { display: none; }

.search-container .search-submit {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 25px;
  color: var(--vortex-orange);
}

.search-container .search-submit ion-icon { --ionicon-stroke-width: 70px; }

.search-container .search-close {
  position: fixed;
  inset: 0;
  cursor: default;
  z-index: -1;
}





/*-----------------------------------*\
  #HERO
\*-----------------------------------*/

.hero__banner {
  /* display: none; */
  margin: auto;
  width: 100%;
  /* width: 80vw; */
  overflow: hidden;
}

.hero {
  --section-padding: 60px;
  margin-block-start: 84px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  min-height: 100vh;
  display: grid;
  align-items: center;
  overflow: hidden;
}

.hero__subtitle {
  color: var(--vortex-orange);
  font-size: var(--fs-6);
  font-weight: var(--fw-700);
  text-transform: uppercase;
}

.hero__title { margin-block: 15px 12px; }

.hero__text {
  color: var(--light-gray-2);
  margin-block-end: 45px;
}





/*-----------------------------------*\
  #sports__gallery
\*-----------------------------------*/

.sports__gallery {
  --section-padding: 60px;
  background-image: var(--gradient);
}

.sports__gallery .has__scrollbar { padding-block-end: 0; }

.sports__gallery .has__scrollbar::-webkit-scrollbar { display: none; }

.sports__gallery-item {
  min-width: calc(50% - 10px);
  scroll-snap-align: start;
}

.sports__gallery-item > img { margin-inline: auto; }





/*-----------------------------------*\
  #LATEST GAME
\*-----------------------------------*/

.section-wrapper {
  position: relative;
  background-color: var(--white);
  z-index: 1;
}

.section-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 57%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.about .section__title { margin-block-end: 80px; }

.about .has__scrollbar {
  margin-inline: -15px;
  padding-inline: 15px;
  scroll-padding-inline-start: 15px;
}

.about-card {
  position: relative;
  box-shadow: var(--shadow-2);
}

.about-card .card__content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--xiketic_90);
  padding: 35px 25px;
}

.about-card .card-badge {
  position: absolute;
  bottom: 100%;
  left: 25px;
}

.about-card .card__title {
  margin-block-end: 12px;
  transition: var(--transition);
}

.about-card .card__title:is(:hover, :focus) { color: var(--vortex-orange); }

.about-card .card__inst {
  color: var(--silver);
  font-size: var(--fs-10);
  font-weight: var(--fw-600);
}

.about-card .card__inst .span { margin-inline-start: 5px; }





/*-----------------------------------*\
  #LIVE MATCH
\*-----------------------------------*/

.video__banner {
  padding-block-start: 0;
  overflow-x: hidden;
}

.video__banner .section__title { margin-block-end: 90px; }

.video__banner-banner {
  position: relative;
  background-color: var(--light-gray-1);
  border-radius: 6px;
  overflow: hidden;
  box-shadow: var(--shadow-3);
}

.video__banner-banner .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--vortex-orange);
  font-size: 60px;
}

.video__banner-player {
  position: relative;
  padding-block-start: var(--section-padding);
  display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
}

.video__banner-player::after {
  content: "VORTEX";
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  font-size: 10rem;
  font-weight: var(--fw-600);
  /* color: var(--cultured-2); */
  color: var(--raisin-black-4);
  z-index: -1;
}

.video__banner-player .player { max-width: max-content; }

.video__banner-player .player-2 { margin-inline-start: auto; }

.video__banner-detail { margin-block: 50px; }

.video__banner-subtitle {
  background-color: var(--vortex-orange);
  color: var(--white);
  font-size: var(--fs-9);
  text-transform: uppercase;
  font-weight: var(--fw-700);
  letter-spacing: 2px;
  padding: 4px 20px;
  max-width: max-content;
  margin-inline: auto;
  margin-block-end: 30px;
}

.video__banner-time {
  color: var(--xiketic);
  font-size: var(--fs-1);
  font-weight: var(--fw-700);
  max-width: max-content;
  margin-inline: auto;
  line-height: 1;
}





/*-----------------------------------*\
  #FEATURED GAME
\*-----------------------------------*/

.activities { background-color: var(--cultured-2); }

.activities .section__title {
  color: var(--xiketic);
  margin-block-end: 90px;
}

.activities .has__scrollbar { gap: 0; }

.activities__card { position: relative; }

.activities__card :is(.card__content, .card__content-overlay) { position: absolute; }

.activities__card .card__content {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 35px;
  transition: var(--transition);
}

.activities__card .card__content-overlay {
  inset: 0;
  background-color: var(--vortex-orange_75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: var(--transition);
}

.activities__card .card__title { margin-block-end: 5px; }

.activities__card .card-meta {
  color: var(--white);
  font-size: var(--fs-11);
  font-weight: var(--fw-500);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 4px;
}

.activities__card .card__content .card-meta { color: var(--platinum); }

.activities__card .card__content ion-icon {
  color: var(--vortex-orange);
  font-size: 14px;
}

.activities__card .card-icon { margin-block-end: 20px; }

.activities__card .card__content-overlay .card__title { color: var(--xiketic); }

.activities__card .card__content-overlay .span { color: var(--white); }

.activities__card:is(:hover, :focus-within) .card__content { opacity: 0; }

.activities__card:is(:hover, :focus-within) .card__content-overlay { opacity: 1; }





/*-----------------------------------*\
  #benefits
\*-----------------------------------*/

.benefits {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.benefits .section__text { margin-block: 50px 60px; }

.benefits .has__scrollbar {
  margin-inline: -15px;
  padding-inline: 15px;
  scroll-padding-inline-start: 15px;
}

.benefits__card {
  box-shadow: var(--shadow-4);
  height: 100%;
}

.benefits__card .card__content {
  position: relative;
  padding: 25px;
  padding-block-start: 40px;
}

.benefits__card .card-badge {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.benefits__card .h3 { font-size: var(--fs-7); }

.benefits__card .card__title {
  transition: var(--transition);
  margin-block-end: 10px;
}

.benefits__card .card__title:is(:hover, :focus) { color: var(--vortex-orange); }

.benefits__card .card__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--vortex-orange);
}

.benefits__card .card__inst {
  font-family: var(--ff-poppins);
  font-weight: var(--fw-800);
}

.benefits__card .card-btn {
  color: inherit;
  font-size: 18px;
  padding: 7px;
  border: 1px solid var(--english-violet);
  border-radius: 4px;
  transition: var(--transition);
}

.benefits__card .card-btn:is(:hover, :focus) {
  background-color: var(--vortex-orange);
  color: var(--xiketic);
  border-color: var(--vortex-orange);
}




/*-----------------------------------*\
  #gallery
\*-----------------------------------*/

.gallery { background-color: var(--white); }

.gallery .section__title { color: var(--xiketic); }

.gallery .section__text {
  color: var(--dim-gray);
  margin-block: 50px 60px;
}

.gallery-list {
  display: grid;
  gap: 50px;
}

.gallery-card .card__banner { margin-block-end: 30px; }

.gallery-card .card-meta-list {
  display: flex;
  align-items: center;
  gap: 20px;
}

.gallery-card .card-meta-item {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--quick-silver);
  font-size: var(--fs-11);
  font-weight: var(--fw-600);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.gallery-card .card-meta-item ion-icon {
  --ionicon-stroke-width: 50px;
  color: var(--vortex-orange);
}

.gallery-card .card-meta-item a.item-text { transition: var(--transition); }

.gallery-card .card-meta-item a.item-text:is(:hover, :focus) { color: var(--vortex-orange); }

.gallery-card .card__title {
  color: var(--raisin-black-1);
  font-size: var(--fs-5);
  font-family: var(--ff-poppins);
  font-weight: var(--fw-800);
  text-transform: uppercase;
  line-height: 1.2;
  margin-block: 10px 15px;
  transition: var(--transition);
}

.gallery-card .card__title:is(:hover, :focus) { color: var(--vortex-orange); }

.gallery-card .card-text {
  color: var(--dim-gray);
  font-size: var(--fs-8);
  font-weight: var(--fw-500);
  line-height: 1.8;
  margin-block-end: 25px;
}

.gallery-card .card-link {
  color: var(--raisin-black-1);
  font-size: var(--fs-9);
  font-weight: var(--fw-700);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: var(--transition);
}

.gallery-card .card-link ion-icon,
.gallery-card .card-link:is(:hover, :focus) { color: var(--vortex-orange); }





/*-----------------------------------*\
  #contact__wrapper
\*-----------------------------------*/

.contact__wrapper { margin-block-end: 80px; }

.contact__wrapper-card {
  background-color: var(--raisin-black-3);
  padding: 40px 15px;
  margin-block-start: -55px;
  border-radius: 80px;
}

.contact__wrapper .h2 {
  font-size: var(--fs-4);
  text-transform: uppercase;
  text-align: center;
  margin-block-end: 30px;
}

.contact__wrapper .input-wrapper {
  max-width: 300px;
  margin-inline: auto;
  margin-block-end: 10px;
}

.contact__wrapper .email-field {
  font-size: var(--fs-9);
  color: var(--white);
  font-weight: var(--fw-500);
  padding: 17px 45px;
  padding-inline-end: 20px;
  outline: none;
}

.contact__wrapper .input-wrapper ion-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  color: var(--vortex-orange);
  --ionicon-stroke-width: 50px;
}

.contact__wrapper .input-wrapper::before {
  --bg: var(--raisin-black-4);
  --skewX: -20deg;
}

.contact__wrapper-btn {
  font-size: var(--fs-9);
  display: flex;
  align-items: center;
  gap: 5px;
  padding-inline: 20px;
  transition: var(--transition);
}

.contact__wrapper-btn::after { display: none; }

.contact__wrapper-btn::before {
  --skewX: -20deg;
  transition: var(--transition);
}

.contact__wrapper-btn:is(:hover, :focus) { color: var(--vortex-orange); }

.contact__wrapper-btn:is(:hover, :focus)::before { background-color: var(--white); }


/* 
  SOCIAL MEDIA
*/

.social__container :is(.social-wrapper, .social-list) {
  display: flex;
  align-items: center;
}

.social__container .social-wrapper { gap: 15px; }

.social__container .social-list { gap: 10px; }

.social-title {
  color: var(--white);
  font-family: var(--ff-poppins);
  font-size: var(--fs-8);
  font-weight: var(--fw-700);
}

.social__container .social-link {
  background-color: var(--jet);
  color: var(--vortex-orange);
  font-size: var(--fs-9);
  padding: 8px 10px;
}

.social__media {
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-block: 20px;
}

.social__media .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



/*-----------------------------------*\
  #FOOTER
\*-----------------------------------*/

.footer { color: var(--roman-silver); }

.footer-top { padding-block-end: var(--section-padding); }

.footer-top .container {
  display: grid;
  gap: 50px;
}

.footer .logo { margin-block-end: 35px; }

.footer-text,
.footer .contact-item {
  font-size: var(--fs-8);
  font-weight: var(--fw-500);
  line-height: 1.8;
}

.footer-text { margin-block-end: 15px; }

.contact-item:not(:last-child) { margin-block-end: 10px; }

.contact-item {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.contact-icon {
  margin-block-start: 3px;
  font-size: 17px;
}

.footer-list-title {
  position: relative;
  color: var(--silver);
  font-family: var(--ff-poppins);
  text-transform: uppercase;
  font-weight: var(--fw-800);
  padding-block-end: 20px;
  margin-block-end: 35px;
}

.footer-list-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 3px;
  background-color: var(--vortex-orange);
}

.footer-link {
  font-size: var(--fs-9);
  max-width: max-content;
  transition: var(--transition);
  padding-block: 8px;
  font-weight: var(--fw-500);
}

.footer-link:is(:hover, :focus) {
  color: var(--vortex-orange);
  transform: translateX(5px);
}

.footer .social-wrapper { margin-block-end: 50px; }

.footer .social-list {
  display: flex;
  gap: 10px;
}

.footer .social-link {
  color: var(--white);
  padding: 10px;
  border-radius: 2px;
}

.footer-contact__wrapper { position: relative; }

.footer-contact__wrapper .email-field {
  background-color: var(--raisin-black-4);
  padding: 12px 20px;
  padding-inline-end: 60px;
  font-size: var(--fs-9);
  color: var(--white);
}

.footer-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 53px;
  background-color: var(--vortex-orange);
  color: var(--xiketic);
  display: grid;
  place-content: center;
}

.footer-bottom-img { display: none; }

.footer-bottom {
  background-color: var(--eerie-black-2);
  padding-block: 20px;
  text-align: center;
}

.copyright {
  font-size: var(--fs-9);
  font-weight: var(--fw-500);
  line-height: 1.8;
}

.copyright-link {
  display: inline-block;
  color: var(--vortex-orange);
  text-decoration: underline !important;
}




/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for larger than 576px screen
 */

@media (min-width: 576px) {

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 540px;
    width: 100%;
    margin-inline: auto;
  }

  .h1 { --fs-2: 7rem; }



  /**
   * HEADER
   */

  .header__actions { gap: 40px; }

  .cart-btn {
    display: block;
    position: relative;
    color: var(--white);
    font-size: 20px;
  }

  .cart-badge {
    position: absolute;
    top: -6px;
    right: -10px;
    background-color: var(--vortex-orange);
    color: var(--eerie-black-1);
    font-size: var(--fs-11);
    border-radius: 20px;
    padding: 3px 5px;
    line-height: 1;
    font-weight: var(--fw-800);
  }



  /**
   * SEARCH BOX
   */

  .search-container :is(.search-field, .search-submit) { font-size: var(--fs-3); }



  /**
   * sports__gallery
   */

  .sports__gallery-item { min-width: calc(33.33% - 13.33px); }



  /**
   * FEATURED GAME 
   */

  .activities .scrollbar__item { min-width: 50%; }



  /**
   * gallery
   */

  .gallery-list {
    grid-template-columns: 75%;
    justify-content: center;
  }



  /**
   * FOOTER
   */

  .footer-top .container { grid-template-columns: 1fr 1fr; }

  .footer-sports__gallery,
  .footer-wrapper { grid-column: 1 / 3; }

}





/**
 * responsive for larger than 768px screen
 */

@media (min-width: 768px) {

  /**
   * REUSED STYLE
   */

  .container { max-width: 720px; }

  .scrollbar__item { min-width: calc(50% - 15px); }



  /**
   * HERO
   */

  .hero__text {
    max-width: 60ch;
    margin-inline: auto;
  }



  /**
   * sports__gallery
   */

  .sports__gallery-item { min-width: calc(25% - 15px); }



  /**
   * LIVE MATCH
   */

  .video__banner-banner .play-btn { font-size: 120px; }

  .video__banner-player::after { font-size: 15rem; }



  /**
   * gallery
   */

  .gallery-list { grid-template-columns: 1fr 1fr; }



  /**
   * contact__wrapper
   */

  .contact__wrapper :is(.input-wrapper, .contact__wrapper-btn) { margin: 0; }

  .contact__wrapper .input-wrapper {
    max-width: unset;
    width: 100%;
  }

  .contact__wrapper .h2 { --fs-4: 2.6rem; }

  .contact__wrapper-form {
    display: flex;
    gap: 10px;
    max-width: 500px;
    width: 100%;
    margin-inline: auto;
  }



  /**
   * FOOTER
   */

  .footer-sports__gallery,
  .footer-wrapper { grid-column: auto; }

  .footer-bottom-img { display: block; }

  .footer-bottom { text-align: left; }

  .footer-bottom .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }


}





/**
 * responsive for larger than 992px screen
 */

@media (min-width: 992px) {

  /**
   * REUSED STYLE
   */

  .container { max-width: 960px; }

  :is(.header, .hero, .video__banner) .container { max-width: unset; }

  .scrollbar__item { min-width: calc(33.33% - 20px); }



  /**
   * HEADER
   */

  .header-top {
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    padding-block: 20px;
  }

  .header-top .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .countdown-text {
    color: var(--quick-silver);
    font-size: var(--fs-10);
    font-weight: var(--fw-600);
  }

  .countdown-text .span {
    display: inline-block;
    color: var(--xiketic);
    font-size: 1.8rem;
    font-weight: var(--fw-700);
    padding: 10px 20px;
    margin-inline: 5px;
  }

  .header :is(.social-wrapper, .social-list) {
    display: flex;
    align-items: center;
  }

  .header .social-wrapper { gap: 15px; }

  .header .social-list { gap: 10px; }

  .social-title {
    color: var(--white);
    font-family: var(--ff-poppins);
    font-size: var(--fs-8);
    font-weight: var(--fw-700);
  }

  .header .social-link {
    background-color: var(--jet);
    color: var(--vortex-orange);
    font-size: var(--fs-9);
    padding: 8px 10px;
  }

  .header__bottom { outline: 1px solid hsla(0, 0%, 0%, 0.2); }

  .header__bottom::before {
    display: block;
    top: 0;
    left: -15px;
    right: auto;
    width: 170px;
    --skewX: 16deg;
  }

  .nav__toggle__btn { display: none; }

  .navbar,
  .navbar.active {
    all: unset;
    margin-inline: auto 15px;
  }

  .navbar__list { display: flex; }

  .navbar__item:not(:last-child) { border-block-end: none; }

  .navbar__link {
    color: var(--white);
    font-family: var(--ff-poppins);
    font-size: var(--fs-11);
    text-transform: uppercase;
    font-weight: var(--fw-700);
    padding: 10px 20px;
  }

  .navbar__link::before {
    display: block;
    opacity: 0;
    transition: var(--transition);
  }

  .navbar__link:is(:hover, :focus) {
    background-color: transparent;
    color: var(--xiketic);
  }

  .navbar__link:is(:hover, :focus)::before { opacity: 1; }



  /**
   * HERO
   */

  .hero { text-align: left; }

  .hero__banner { display: block; }

  .hero .container {
    display: grid;
    grid-template-columns: 1fr 0.9fr;
    align-items: center;
    gap: 50px;
  }

  .btn { margin-inline: 0; }



  /**
   * sports__gallery
   */

  .sports__gallery-item { min-width: calc(20% - 16px); }



  /**
   * LATEST GAME
   */

  .about .has__scrollbar { padding-block-end: 0; }



  /**
   * LIVE MATCH
   */

  .video__banner-banner {
    max-width: 700px;
    margin-inline: auto;
  }

  .video__banner-player {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }

  .video__banner-detail { margin-block: 0; }



  /**
   * FEATURED GAME
   */

  .activities .scrollbar__item { min-width: 33.33%; }



  /**
   * gallery
   */

  .gallery-list {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }



  /**
   * contact__wrapper
   */

  .contact__wrapper-form { max-width: 650px; }

  /**
   * CONTACT
   */

   .contact-wrapper {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 80px;
    align-items: center;
  }

  .contact-form { margin-bottom: 0; }



  /**
   * FOOTER
   */

  .footer .container {
    grid-template-columns: 1fr 0.7fr 0.7fr;
    column-gap: 80px;
  }

}





/**
 * responsive for larger than 1200px screen
 */

@media (min-width: 1200px) {

  /**
   * REUSED STYLE
   */

  .container,
  :is(.header, .hero, .video__banner) .container { max-width: 1230px; }

  .h1 { --fs-2: 7.5rem; }

  .scrollbar__item { min-width: calc(25% - 22.5px); }



  /**
   * HEADER
   */

  .header__bottom::before { width: 270px; }

  .navbar__link {
    font-size: var(--fs-9);
    padding-inline: 25px;
  }



  /**
   * HERO
   */

  .hero .container { grid-template-columns: 1fr 1fr; }



  /**
   * sports__gallery
   */

  .sports__gallery-item { min-width: calc(16.66% - 16.66px); }



  /**
   * LIVE MATCH
   */

  .section-wrapper::before { height: 72%; }

  .video__banner-banner { max-width: max-content; }

  .video__banner-player::after { font-size: 35rem; }



  /**
   * FEATURED GAME
   */

  .activities .has__scrollbar { padding-block-end: 0; }

  .activities .scrollbar__item { min-width: 25%; }



  /**
   * benefits
   */

  .benefits .has__scrollbar { padding-block-end: 15px; }



  /**
   * contact__wrapper
   */

  .contact__wrapper-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 70px;
  }

  .contact__wrapper .h2 { margin-block-end: 0; }

  .contact__wrapper-form { margin-inline: 0; }



  /**
   * FOOTER
   */

  .footer .container { grid-template-columns: 1fr 0.7fr 0.7fr 1fr; }

}

/* 
  * btn__trans
*/

.btn__trans {
  -webkit-animation: jello-diagonal-1 0.8s both;
	        animation: jello-diagonal-1 0.8s both;
}

.btn__trans:hover {
  transform: scale(1.05);
  
  transition: var(--transition);
  -webkit-animation: jello-diagonal-1 0.8s both;
	        animation: jello-diagonal-1 0.8s both;
}

 @-webkit-keyframes jello-diagonal-1 {
  0% {
    -webkit-transform: skew(0deg 0deg);
            transform: skew(0deg 0deg);
  }
  30% {
    -webkit-transform: skew(25deg 25deg);
            transform: skew(25deg 25deg);
  }
  40% {
    -webkit-transform: skew(-15deg, -15deg);
            transform: skew(-15deg, -15deg);
  }
  50% {
    -webkit-transform: skew(15deg, 15deg);
            transform: skew(15deg, 15deg);
  }
  65% {
    -webkit-transform: skew(-5deg, -5deg);
            transform: skew(-5deg, -5deg);
  }
  75% {
    -webkit-transform: skew(5deg, 5deg);
            transform: skew(5deg, 5deg);
  }
  100% {
    -webkit-transform: skew(0deg 0deg);
            transform: skew(0deg 0deg);
  }
}
@keyframes jello-diagonal-1 {
  0% {
    -webkit-transform: skew(0deg 0deg);
            transform: skew(0deg 0deg);
  }
  30% {
    -webkit-transform: skew(25deg 25deg);
            transform: skew(25deg 25deg);
  }
  40% {
    -webkit-transform: skew(-15deg, -15deg);
            transform: skew(-15deg, -15deg);
  }
  50% {
    -webkit-transform: skew(15deg, 15deg);
            transform: skew(15deg, 15deg);
  }
  65% {
    -webkit-transform: skew(-5deg, -5deg);
            transform: skew(-5deg, -5deg);
  }
  75% {
    -webkit-transform: skew(5deg, 5deg);
            transform: skew(5deg, 5deg);
  }
  100% {
    -webkit-transform: skew(0deg 0deg);
            transform: skew(0deg 0deg);
  }
}

.flicker-5 {
	-webkit-animation: flicker-5 8s linear infinite both;
	        animation: flicker-5 8s linear infinite both;
}


/**
 * ----------------------------------------
 * animation flicker-5
 * ----------------------------------------
 */
 @-webkit-keyframes flicker-5 {
  0%,
  100% {
    opacity: 1;
  }
  -0.02% {
    opacity: 1;
  }
  0% {
    opacity: 1;
  }
  1% {
    opacity: 1;
  }
  1.02% {
    opacity: 1;
  }
  8.98% {
    opacity: 1;
  }
  9% {
    opacity: 0;
  }
  9.8% {
    opacity: 0;
  }
  9.82% {
    opacity: 1;
  }
  9.48% {
    opacity: 1;
  }
  9.5% {
    opacity: 1;
  }
  9.6% {
    opacity: 1;
  }
  9.62% {
    opacity: 1;
  }
  14.98% {
    opacity: 1;
  }
  15% {
    opacity: 0.5;
  }
  15.8% {
    opacity: 0.5;
  }
  15.82% {
    opacity: 1;
  }
  15.18% {
    opacity: 1;
  }
  15.2% {
    opacity: 0.7;
  }
  16% {
    opacity: 0.7;
  }
  16.02% {
    opacity: 1;
  }
  15.48% {
    opacity: 1;
  }
  15.5% {
    opacity: 0.5;
  }
  16.2% {
    opacity: 0.5;
  }
  16.22% {
    opacity: 1;
  }
  16.98% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  17.8% {
    opacity: 1;
  }
  17.82% {
    opacity: 1;
  }
  20.48% {
    opacity: 1;
  }
  20.5% {
    opacity: 0.9;
  }
  21.3% {
    opacity: 0.9;
  }
  21.32% {
    opacity: 1;
  }
  20.98% {
    opacity: 1;
  }
  21% {
    opacity: 1;
  }
  22% {
    opacity: 1;
  }
  22.02% {
    opacity: 1;
  }
  39.98% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  41% {
    opacity: 1;
  }
  41.02% {
    opacity: 1;
  }
  40.48% {
    opacity: 1;
  }
  40.5% {
    opacity: 0.6;
  }
  41.4% {
    opacity: 0.6;
  }
  41.42% {
    opacity: 1;
  }
  41.98% {
    opacity: 1;
  }
  42% {
    opacity: 1;
  }
  42.8% {
    opacity: 1;
  }
  42.82% {
    opacity: 1;
  }
  59.98% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  61% {
    opacity: 1;
  }
  61.02% {
    opacity: 1;
  }
  60.18% {
    opacity: 1;
  }
  60.2% {
    opacity: 0.2;
  }
  61% {
    opacity: 0.2;
  }
  61.02% {
    opacity: 1;
  }
  60.78% {
    opacity: 1;
  }
  60.8% {
    opacity: 0.4;
  }
  61.6% {
    opacity: 0.4;
  }
  61.62% {
    opacity: 1;
  }
  61.38% {
    opacity: 1;
  }
  61.4% {
    opacity: 0;
  }
  62.2% {
    opacity: 0;
  }
  62.22% {
    opacity: 1;
  }
  61.78% {
    opacity: 1;
  }
  61.8% {
    opacity: 1;
  }
  62.8% {
    opacity: 1;
  }
  62.82% {
    opacity: 1;
  }
  75.98% {
    opacity: 1;
  }
  76% {
    opacity: 1;
  }
  77% {
    opacity: 1;
  }
  77.02% {
    opacity: 1;
  }
  77.98% {
    opacity: 1;
  }
  78% {
    opacity: 0.7;
  }
  78.8% {
    opacity: 0.7;
  }
  78.82% {
    opacity: 1;
  }
  78.98% {
    opacity: 1;
  }
  79% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  80.02% {
    opacity: 1;
  }
  99.98% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
  101% {
    opacity: 1;
  }
  101.02% {
    opacity: 1;
  }
}
@keyframes flicker-5 {
  0%,
  100% {
    opacity: 1;
  }
  -0.02% {
    opacity: 1;
  }
  0% {
    opacity: 1;
  }
  1% {
    opacity: 1;
  }
  1.02% {
    opacity: 1;
  }
  8.98% {
    opacity: 1;
  }
  9% {
    opacity: 0;
  }
  9.8% {
    opacity: 0;
  }
  9.82% {
    opacity: 1;
  }
  9.48% {
    opacity: 1;
  }
  9.5% {
    opacity: 1;
  }
  9.6% {
    opacity: 1;
  }
  9.62% {
    opacity: 1;
  }
  14.98% {
    opacity: 1;
  }
  15% {
    opacity: 0.5;
  }
  15.8% {
    opacity: 0.5;
  }
  15.82% {
    opacity: 1;
  }
  15.18% {
    opacity: 1;
  }
  15.2% {
    opacity: 0.7;
  }
  16% {
    opacity: 0.7;
  }
  16.02% {
    opacity: 1;
  }
  15.48% {
    opacity: 1;
  }
  15.5% {
    opacity: 0.5;
  }
  16.2% {
    opacity: 0.5;
  }
  16.22% {
    opacity: 1;
  }
  16.98% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  17.8% {
    opacity: 1;
  }
  17.82% {
    opacity: 1;
  }
  20.48% {
    opacity: 1;
  }
  20.5% {
    opacity: 0.9;
  }
  21.3% {
    opacity: 0.9;
  }
  21.32% {
    opacity: 1;
  }
  20.98% {
    opacity: 1;
  }
  21% {
    opacity: 1;
  }
  22% {
    opacity: 1;
  }
  22.02% {
    opacity: 1;
  }
  39.98% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  41% {
    opacity: 1;
  }
  41.02% {
    opacity: 1;
  }
  40.48% {
    opacity: 1;
  }
  40.5% {
    opacity: 0.6;
  }
  41.4% {
    opacity: 0.6;
  }
  41.42% {
    opacity: 1;
  }
  41.98% {
    opacity: 1;
  }
  42% {
    opacity: 1;
  }
  42.8% {
    opacity: 1;
  }
  42.82% {
    opacity: 1;
  }
  59.98% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  61% {
    opacity: 1;
  }
  61.02% {
    opacity: 1;
  }
  60.18% {
    opacity: 1;
  }
  60.2% {
    opacity: 0.2;
  }
  61% {
    opacity: 0.2;
  }
  61.02% {
    opacity: 1;
  }
  60.78% {
    opacity: 1;
  }
  60.8% {
    opacity: 0.4;
  }
  61.6% {
    opacity: 0.4;
  }
  61.62% {
    opacity: 1;
  }
  61.38% {
    opacity: 1;
  }
  61.4% {
    opacity: 0;
  }
  62.2% {
    opacity: 0;
  }
  62.22% {
    opacity: 1;
  }
  61.78% {
    opacity: 1;
  }
  61.8% {
    opacity: 1;
  }
  62.8% {
    opacity: 1;
  }
  62.82% {
    opacity: 1;
  }
  75.98% {
    opacity: 1;
  }
  76% {
    opacity: 1;
  }
  77% {
    opacity: 1;
  }
  77.02% {
    opacity: 1;
  }
  77.98% {
    opacity: 1;
  }
  78% {
    opacity: 0.7;
  }
  78.8% {
    opacity: 0.7;
  }
  78.82% {
    opacity: 1;
  }
  78.98% {
    opacity: 1;
  }
  79% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  80.02% {
    opacity: 1;
  }
  99.98% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
  101% {
    opacity: 1;
  }
  101.02% {
    opacity: 1;
  }
}
