.wht__btn {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #00BB2D;
    color: var(--white);
    font-size: 40px;
    padding: 5px;
    border-radius: 50%;
    z-index: 3;
    opacity: 1;
    visibility: visible;
    transition: var(--transition);
  }
  