.news { background-color: var(--white); }

.news .section__title { color: var(--xiketic); }

.news .section__text {
  color: var(--dim-gray);
  margin-block: 50px 60px;
}

.news__list {
  display: grid;
  gap: 50px;
}

.news__card .card__banner { margin-block-end: 30px; }

.news__card .card__meta__list {
  display: flex;
  align-items: center;
  gap: 20px;
}

.news__card .card__meta__item {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--quick-silver);
  font-size: var(--fs-11);
  font-weight: var(--fw-600);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.news__card .card__meta__item ion-icon {
  --ionicon-stroke-width: 50px;
  color: var(--vortex-orange);
}

.news__card .card__meta__item a.item__text { transition: var(--transition); }

.news__card .card__meta__item a.item__text:is(:hover, :focus) { color: var(--vortex-orange); }

.news__card .card__title {
  color: var(--raisin-black-1);
  font-size: var(--fs-5);
  font-family: var(--ff-poppins);
  font-weight: var(--fw-800);
  text-transform: uppercase;
  line-height: 1.2;
  margin-block: 10px 15px;
  transition: var(--transition);
}

.news__card .card__title:is(:hover, :focus) { color: var(--vortex-orange); }

.news__card .card__text {
  color: var(--dim-gray);
  font-size: var(--fs-8);
  font-weight: var(--fw-500);
  line-height: 1.8;
  margin-block-end: 25px;
}

.news__card .card__link {
  color: var(--raisin-black-1);
  font-size: var(--fs-9);
  font-weight: var(--fw-700);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: var(--transition);
}

.news__card .card__link ion-icon,
.news__card .card__link:is(:hover, :focus) { color: var(--vortex-orange); }



@media (min-width: 576px) {
    .news__list {
        grid-template-columns: 75%;
        justify-content: center;
      }
}

@media (min-width: 768px) {
    .news__list { grid-template-columns: 1fr 1fr; }
}

@media (min-width: 992px) {
    .news__list {
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
    }
}